import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import styles from './Brand.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

const Brand2 = () => {
	const menuContents = [{ title: "한신더휴 프리미엄 소개", url: "/brand/intro" }, { title: "홍보 영상", url: "/brand/video" }];
	const [isScroll, setIsScroll] = useState(false);
	const [isVideoLoaded, setIsVideoLoaded] = useState(false);
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const handleScroll = () => {
			setIsScroll(window.scrollY > 0);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		// 페이지가 로드되면 비디오 로드 상태를 true로 설정
		setIsVideoLoaded(true);
	}, []);

	return (
		<div className={styles.container}>
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="브랜드" />
			<MenuBar contents={menuContents} />

			<div className={styles.videoContainer}>
				{isVideoLoaded && (
					<div style={{ padding: "50vw 0 0 0", position: "relative" }}>
						<iframe
							src="https://player.vimeo.com/video/1027560645?badge=0&autopause=0&player_id=0&app_id=58479"
							style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "80%" }}
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
							title="평택브레인시티 한신더휴"
						></iframe>
					</div>
				)}
			</div>

			<Footer />
		</div>
	);
}

export default Brand2;
