import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './FloorPlan.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/FloorPlan/FloorPlan1/page1.jpg";
import page2 from "../../assets/FloorPlan/FloorPlan1/page2.jpg";


const FloorPlan1 = () => {
	const menuContents = [
		{ title: "59A", url: "/FloorPlan/59A" },
		{ title: "59B", url: "/FloorPlan/59B" },
		{ title: "84A", url: "/FloorPlan/84A" },
		{ title: "84B", url: "/FloorPlan/84B" },
		{ title: "84C", url: "/FloorPlan/114A" },
		{ title: "84D", url: "/FloorPlan/114B" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

			
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="세대안내" />

			<MenuBar contents={menuContents} />

			<img src={page1} className={styles.image} alt="FloorPlan-image-1" />
			<img src={page2} className={styles.image2} alt="FloorPlan2-image-2" />

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 CG 및 이미지는 소비자의 이해를 돕기 위해 제작된 것으로 실제와 다르며 인·허가 과정에서 다소 변경될 수 있으니 견본주택을 방문하시어 직접 확인하시기 바랍니다.
				</div>
				<div className={styles.notice}>
					※ 본 홈페이지의 제작과정상 오탈자 등 오류가 있을 수 있으므로 계약 시 반드시 확인하시기 바랍니다.
				</div>
		
			</div>

			<Footer />
		</div>
	)
}

export default FloorPlan1;
