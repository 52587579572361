import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useMediaQuery } from "react-responsive";
import styles from "./Popup.module.scss";

const Popup = ({ onClosed, popupImage, numbering }) => {
    const [type, setType] = useState(0);
    const [cookies, setCookie] = useCookies();
    const isPopupShown = cookies[`Popup_Cookie${numbering}`] || false; // 기본값 처리
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    const getExpiredDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    };

    useEffect(() => {
        if (type === 1) {
            const expires = getExpiredDate(1);
            setCookie(`Popup_Cookie${numbering}`, true, { path: '/', expires });
            onClosed(false);
        } else if (type === 2) {
            onClosed(false);
        }
    }, [type]); // cookies 제거, type만 의존

    if (isPopupShown) return null; // 팝업이 표시되지 않을 조건

    return (
        <div className={styles.backgroundContainer}>
            <div className={styles.contentContainer}>
                <img
                    className={styles.popupImg}
                    // 2번 이미지 크기만 PC에서 다르게 설정
                    style={!isMobile && numbering === 2 ? { width: '23vw' } : 
                           !isMobile && numbering === 3 ? { width: '40vw' } : {}}
                    src={popupImage}
                    alt={`hansinduhyue-popup-image${numbering}`}
                />
                <div className={styles.btnContainer}>
                    <div className={styles.todayNotOpenBtn} onClick={() => setType(1)}>오늘 하루 보지 않기</div>
                    <div className={styles.closeBtn} onClick={() => setType(2)}>닫기</div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
